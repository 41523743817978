export const pageData = {
  title: '2021 Top 10 Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '2021 Top 10 Real Estate Websites',
      url: '/top-10-real-estate-websites/2021',
    },
  ],
  uri: '/top-10-real-estate-websites/2021',
}

export const websiteTexts = [
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Jills Zeder \nGroup',
    subtitle: 'Miami, FL',
    description:
      'There are many ways to describe JillsZeder.com, but “showstopping” is the most apt. From the full-screen banner image of luxury estates, to the ultra-high-end listings, to the record-breaking sales, it’s clear why The Jills Zeder Group is the #1 real estate team in the nation. Fittingly, the site was awarded the Best Overall Website award at the 2021 RealTrends Website Rankings.',
  },
  {
    title: 'Josh \nFlagg ',
    subtitle: 'Los Angeles, CA',
    description:
      'JoshFlagg.com opens with an exclusive invitation for their visitors to enter their site. Once inside, we are granted grants you access to some of the most prized listings in all of La La Land, all showcased in crisp, full-sized photos and complemented by intuitive navigation. Each component of this website has also been expertly optimized for smart screens, leading to a Best Mobile Website honorable mention at the 2021 RealTrends Website Rankings.',
  },
  {
    title: 'Joyce \nRey',
    subtitle: 'New York, NY',
    description:
      'To visit JoyceRey.com is to get a privileged look into Beverly Hills’ most exclusive estates. Its namesake agent, after all, is a record-breaking maven who has amassed over $5 billion in sales over her storied career. And with her stunning website, she can add another trophy to her trove of achievements: an honorable mention for Best Video at the 2021 RealTrends Website Rankings.',
  },
  {
    title: 'Hilton \n& Hylan',
    subtitle: 'Beverly Hills, CA',
    description:
      'Snippets of a leisurely limousine ride, rows of luxury homes passing by, picturesque palm trees overhead — these are the things that greet visitors of HiltonHyland.com. More than a real estate website, it’s an encapsulation of the glamor, romance, and prestige that only Beverly Hills can offer. With its exquisitely minimalist aesthetic, browsing this site almost feels like leafing through a glossy magazine.',
  },
  {
    title: 'Gary \nGold',
    subtitle: 'Los Angeles, CA',
    description:
      'There’s a reason why Gary Gold’s website address is SoldByGold.com. He is, after all, the agent who sold the iconic Playboy Mansion and the sprawling Chartwell Estate, both of which fetched over $100 million on the market. For an agent who truly understands the life of luxury, we designed a website whose opulence is undeniable, from the classic black-and-gold theme, to the carousel of ultra-luxury homes, to the subtle images integrated into the background.',
  },
  {
    title: 'Sally Forster \nJones',
    subtitle: 'Beverly Hills, CA',
    description:
      'Sally Forster Jones has sold over $8.5 billion worth of real estate, but her secret to success is incredibly simple: providing VIP treatment to each and every client. It’s only natural, then, that simplicity is the guiding principle for her website. White space, breathtaking photos, user-friendly navigation, and clever use of the parallax effects come together to prove that minimalism can indeed be inspiring.',
  },
  {
    title: 'The Altman \nBrothers',
    subtitle: 'Los Angeles, CA',
    description:
      'The Altman Brothers are what all real estate agents hope to be: successful, respected, and household-name famous (thanks to “Million Dollar Listing”). TheAltmanBrothers.com, meanwhile, is what all luxury real estate websites should aspire to be: sophisticated, informative, and visually arresting. Is it any wonder why this site nabbed an honorable mention for Best Design at the 2021 RealTrends Website Rankings?',
  },
  {
    title: 'Williams & \nWilliams Estates',
    subtitle: 'Beverly Hills, CA',
    description:
      'You don’t just browse TheWilliamsEstates.com – you are entranced by it. From the get-go, you’re captivated by photos of infinity pools overlooking the Pacific Ocean and hillside homes that have stunning views of the L.A. skyline. But this website is more than just a delight for the eyes as it also highlights the countless achievements that have made Williams & Williams a titan in the Los Angeles high-end property market.',
  },
  {
    title: 'Ben \nBacal',
    subtitle: 'West Hollywood, CA',
    description:
      'When you represent some of the most elite real estate in Los Angeles, you let the properties do the talking. That’s why we designed BenBacal.com as a canvas on which these high-end homes can be the star of the show. The sleek layout, full-screen photos, abundant white space, and subtle branding evoke effortless sophistication, just like Ben Bacal himself.',
  },
]
